import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldPhoneNumberInput, FieldSelect } from '../../../components';

import css from './ConfirmSignupForm.module.css';
import { discoveredServiceVia } from '../../../constants';

const ConfirmSignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        authInfo,
        idp,
      } = formRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'ConfirmSignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'ConfirmSignupForm.emailInvalid',
        })
      );

      //Telephone
      const telephoneRequiredMessage = validators.requiredStringNoTrim(intl.formatMessage({
        id: 'SignupForm.phoneNumberRequired',
      }))

      const telephoneMinLength = validators.minLength(
        intl.formatMessage({ id: "SignupForm.telephoneTooShort" }, { minLength: validators.TELEPHONE_MIN_LENGTH }),
        validators.TELEPHONE_MIN_LENGTH,
      )
      const telephoneMaxLength = validators.maxLength(
        intl.formatMessage({ id: "SignupForm.telephoneTooLong" }, { minLength: validators.TELEPHONE_MIN_LENGTH }),
        validators.TELEPHONE_MIN_LENGTH,
      )

      const telephoneValidators = validators.composeValidators(
        telephoneRequiredMessage,
        telephoneMinLength,
        telephoneMaxLength
      );

      const optionPlaceHolder = intl.formatMessage({ id: "SignupForm.optionPlaceHolder" })
      const howKnowCocopoolLabel = intl.formatMessage({ id: "SignupForm.howKnowCocopoolLabel" })
      const whatYouWantToDoLabel = intl.formatMessage({ id: "SignupForm.whatYouWantToDoLabel" })
      const howKnowCocopoolRequiredMessage = validators.required(intl.formatMessage({ id: "SignupForm.howKnowCocopoolrequiredMessage" }));
      const whatYouWantToDoRequiredMessage = validators.required(intl.formatMessage({ id: "SignupForm.whatYouWantToDoRequiredMessage" }));
      const bookPoolLabel = intl.formatMessage({ id: "SignupForm.bookPoolLabel" });
      const sharePoolLabel = intl.formatMessage({ id: "SignupForm.sharePoolLabel" });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      // If authInfo is not available we should not show the ConfirmForm
      if (!authInfo) {
        return;
      }

      // Initial values from idp provider
      const { email, firstName, lastName } = authInfo;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({
                id: 'ConfirmSignupForm.emailLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'ConfirmSignupForm.emailPlaceholder',
              })}
              initialValue={email}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'ConfirmSignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ConfirmSignupForm.firstNamePlaceholder',
                })}
                initialValue={firstName}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'ConfirmSignupForm.firstNameRequired',
                  })
                )}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'ConfirmSignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ConfirmSignupForm.lastNamePlaceholder',
                })}
                initialValue={lastName}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'ConfirmSignupForm.lastNameRequired',
                  })
                )}
              />
            </div>

            {/* Telephone */}
            <FieldPhoneNumberInput
              className={css.phoneNumber}
              type="Number"
              id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
              name="phoneNumber"
              autoComplete="phoneNumber"
              label={intl.formatMessage({
                id: 'SignupForm.phoneNumberLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.phoneNumberPlaceholder',
              })}
              validate={telephoneValidators}
            />

            {/* Discovered Services Via */}
            <FieldSelect
              className={css.password}
              id="discoveredServiceVia"
              name="discoveredServiceVia"
              label={howKnowCocopoolLabel}
              validate={howKnowCocopoolRequiredMessage}
            >
              <option disabled value="">
                {optionPlaceHolder}
              </option>
              {discoveredServiceVia.map((item) =>
                <option key={item.key} value={item.value}>
                  {item.option}
                </option>
              )}
            </FieldSelect>

            {/* Choose your Type (Provider/Customer) */}
            <FieldSelect
              className={css.password}
              id="whatToDo"
              name="whatToDo"
              label={whatYouWantToDoLabel}
              validate={whatYouWantToDoRequiredMessage}
            >
              <option disabled value="">{optionPlaceHolder}</option>
              <option value="bookPool_c">{bookPoolLabel}</option>
              <option value="sharePool_p">{sharePoolLabel}</option>
            </FieldSelect>
          </div>

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idp }} />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
